// colors
$black: #000000;
$white: #ffffff;
$red: #d52b1e;

// layout
$mobile-margin: 20px;
$tablet-margin: 60px;
$desktop-margin-sm: 5.85%;
$desktop-margin-lg: 8.33%;

// z-index
$z-zero: 0;
$z-base: 100;
$z-nav: 300;
$z-search: 400;
$z-player: 600;
$z-modal-bg: 900;
$z-modal: 1000;
