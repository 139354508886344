@import "../../utils/scss/index.scss";

.landing {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: #000000; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #000,
    #4286f4
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #000,
    #4286f4
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  &__main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    &__image {
      height: 40%;
    }
  }

  &__footer {
    bottom: 20px;
    width: 100%;
    position: absolute;
    &__logo {
      height: 50px;
    }
  }

  &__begin-btn {
    color: white;
    position: relative;
    margin: 0 auto;
    top: 50%;
    position: absolute;
    background-color: #3a84f5;
    padding: 10px 40px;
    border-radius: 3px;
    text-shadow: 0px 2px 4px #313131;
    box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.4);
  }
}
