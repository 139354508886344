@import "~normalize.css"; /* bring in normalize.css styles */
@import "./utils/scss/index.scss";

body {
  background-color: $black;
  color: white;
  position: relative;
  margin: 0;
  overflow-x: hidden;
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:global {
  .full-height {
    height: 100% !important;
  }
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;

  cursor: pointer;
  background: none;
  border: none;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
